.product-container{
    margin-top: 7%;
}

.coupon-container, .btn-container{
    width: 75%;
}

/* mobile screen */
@media (max-width: 600px) {
    .coupon-container, .btn-container{
        width: 50%;
    }
}

