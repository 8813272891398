.banner-container {
  height: 400px;
}

.margin-bottom {
  margin-bottom: 50px;
}

.padding-y {
  padding-top: 50px;
  padding-bottom: 50px;
}

.text-container > div {
  width: 50%;
}

.product-wrapper {
  width: 60%;
}

.promotion-product-container {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.49);
  display: flex;
  align-items: center;
  justify-content: center;
}

.promotion-product-container img {
  height: inherit;
  width: inherit;
  border-radius: 50%;
}

.detail-wrapper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px 4px;
  width: 95%;
  border-radius: 10px;
}

.detail {
  width: 75%;
}

.detail > div:nth-child(even) {
  display: flex;
  justify-content: flex-end;
}

.detail li {
  line-height: 1.5;
  margin-bottom: 12px;
}

.image-container {
  width: 260px;
}

@media only screen and (min-width: 1600px) {
  .banner-container {
    height: auto;
  }

  .product-wrapper {
    width: 40%;
  }

  .promotion-product-container {
    height: 145px;
    width: 145px;
  }

  .detail {
    width: 50%;
  }
}

@media only screen and (max-width: 1200px) {
  .product-wrapper,
  .detail {
    width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .banner-container {
    height: 300px;
  }

  .text-container > div {
    width: 70%;
  }

  .product-wrapper,
  .detail {
    width: 90%;
  }

  .promotion-product-container {
    height: 95px;
    width: 95px;
  }

  .detail-wrapper {
    width: 100%;
  }

  .detail > div:nth-child(even),
  .detail > div:nth-child(odd) {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .banner-container {
    height: 200px;
  }

  /* .banner-container img {
    object-fit: fill !important;
  } */

  .text-container > div {
    width: 90%;
  }

  .text-container > div > h6 {
    font-size: 12px;
  }

  .promotion-product-container {
    height: 140px;
    width: 140px;
    margin-bottom: 20px;
  }

  .image-container {
    margin-bottom: 12px;
  }

  .detail-text {
    font-size: 13px;
  }

  .order-btn {
    width: 90% !important;
  }
}
