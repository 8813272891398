.background_container{
    width: 100vw;
    height: 100vh;
    background-image: url("../../../assets/background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
}

.login_container {
    width: 430px;
    height: 470px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    color: blanchedalmond;
}

.logo_container {
    width: 75px;
    height: 75px;
    background-color: rgb(245, 245, 220, 0.9);
    border-radius: 50%;
    margin: 20px auto;
    display: flex;
    justify-content: center;
}

.form-container {
    width: 85%;
    margin: 8% auto;
}

.form-container input {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid white;
    border-radius: 0px;
    color: white;
}

.form-container input:focus {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid white;
    box-shadow: none;
    color: white;
}

.form-container input::placeholder{
    color: rgba(255, 255, 255, 0.6) !important;
}