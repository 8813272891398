.category-nav .navbar .nav-item .dropdown-menu { 
    display: none; 
    background-color: whitesmoke;
}

.category-nav .navbar .nav-item:hover .dropdown-menu { 
    display: block; 
}

.category-nav .dropdown-toggle::after {
    visibility: hidden;
}
