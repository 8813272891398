.policyContainer {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 60px;
}

.wrapper {
    width: 85%;
}

.wrapper p, .wrapper ul {
    font-size: 14px;
}