.cart-container {
    margin-top: 5%;
}

.img-container {
    max-height: 160px;
}

.table-style td {
    padding-top: 10px;
    padding-bottom: 10px;
}

.address-container p {
    margin-bottom: 0.2rem;
}

/* large laptop screen  */
@media (max-width: 1200px) {
    .img-container{
        height: 100%;
        max-height: 100%;
    }
}

/* tablet screen  */
@media (max-width: 768px) {
    .img-container{
        height: 100%;
        max-height: 100%;
    }
}

/* mobile screen  */
@media (max-width: 600px) {
    .img-container{
        /* height: 100%; */
        max-height: 150px;
    }
}