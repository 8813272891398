.background-container {
  min-height: var(--containerHeightLg);
  background-image: url("../../assets/home-page-bg.jpg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
}

.hero-container {
  min-height: var(--containerHeightLg);
  background-color: var(--halfWhite);
  margin-bottom: 125px;
}

.header {
  min-height: var(--containerHeightLg);
  /* width: 45%; */
  position: absolute;
}

.header-container {
  width: 45%;
}

.shop-button {
  width: 50%;
}

.card-wrapper {
  margin-bottom: 50px;
  line-height: 1.7;
}

.card-wrapper img {
  max-height: 200px;
}

.card-wrapper p {
  color: #808891;
}

.col-class {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.col-class-div {
  text-align: left;
}

.brand-container {
  background-color: var(--halfWhite);
  padding: 30px 45px;
  margin-bottom: 100px;
}

.subscription-container {
  background-color: #3e3b4c;
  padding: 50px 40px 70px 40px;
  margin-bottom: 100px;
}

.subscription-header {
  color: var(--orange);
  text-align: center;
  margin-bottom: 80px;
}

.plan-card-container {
  width: 70%;
}

.plan-card {
  padding: 0px;
  padding-bottom: 40px;
  border-radius: 10px;
  height: 100%;
}

.price-container {
  color: white;
  text-align: center;
  border-radius: 10px;
  padding: 30px 0px;
}

.features-container {
  margin: 30px auto 45px auto;
}

.plan-button {
  font-weight: bold;
  width: 75%;
}

.social-media-cta a {
  color: initial;
}

.blog-container {
  margin-bottom: 70px;
  background-image: url("../../assets/blog-bg.jpg");
}

.blog-col {
  border-right: 1px solid rgb(255, 255, 255, 0.4);
}

.details {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.details img {
  width: 32px;
  height: 32px;
}

.contact-container {
  background-color: var(--halfWhite);
  font-size: 12px;
}

.contact-container h6 {
  font-size: 14px;
}

.contact-container ul {
  line-height: 1.9;
  list-style: none;
  padding-left: 0;
}

.contact-container ul a {
  text-decoration: none;
  color: #575757;
}

.contact-container ul a:hover {
  color: black;
}

.color-gray {
  color: #575757;
}

#button-email:focus,
#button-email:active {
  box-shadow: var(--buttonBoxshadowOrange);
}

.img1 {
  max-height: 300px;
}

/* small laptop screen */
@media (max-width: 1200px) {
  .plan-card-container {
    width: 80%;
  }
}

/* tablet screen  */
@media (max-width: 768px) {
  .background-container {
    min-height: var(--containerHeightMd);
    background-position: 50% center;
  }

  .hero-container {
    min-height: var(--containerHeightMd);
    margin-bottom: 25px;
  }

  .header {
    min-height: var(--containerHeightMd);
    background-color: #fff;
    margin-top: var(--containerHeightMd);
    justify-content: center;
    text-align: center;
  }

  .header-container {
    width: 80%;
  }

  .shop-button {
    width: 75%;
  }

  .card-wrapper {
    margin-top: var(--containerHeightMd);
  }

  .plan-card-container {
    width: 100%;
  }

  .plan-button {
    width: 70% !important;
  }

  .brand-container {
    padding: 10px;
  }

  .col-class-div {
    text-align: center;
  }

  .blog-col {
    border: none;
    border-bottom: 1px solid rgb(255, 255, 255, 0.4);
    padding-bottom: 18px;
  }
}

/* mobile screen  */
@media (max-width: 600px) {
  .background-container,
  .hero-container {
    min-height: 300px;
  }

  .header {
    min-height: 325px;
    margin-top: 325px;
  }

  .card-wrapper {
    margin-top: 400px;
  }

  .plan-card-container {
    width: 90%;
  }

  .plan-card {
    margin-bottom: 25px;
  }

  .blog-col {
    border-right: none;
    border-bottom: 1px solid rgb(255, 255, 255, 0.4);
    padding: 0px 18px 18px 18px !important;
  }

  .blog-col-2 {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
}
