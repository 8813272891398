.dividerText {
    display: flex;
    flex-direction: row;
}

.dividerText:before, .dividerText:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #ced4da;
    margin: auto;
}

.dividerText:before {
    margin-right: 10px
}

.dividerText:after {
    margin-left: 10px
}