.formInput {
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #ced4da;
}

.formInput:focus {
    box-shadow: none;
    border-color: rgb(112, 112, 112);
}

.btnClass {
    background-color: var(--orange);
    border-color: var(--orange);
    margin-top: 16px;   
}

.btnClass:hover, .btnClass:focus {
    background-color: var(--orange);
    border-color: var(--orange);
}

.btnClass:focus {
    box-shadow: var(--buttonBoxshadowOrange);
}

.btnClass:active:focus {
    box-shadow: var(--buttonBoxshadowOrange);
}

.contactInfo {
    line-height: 2;
    font-size: 15px;
}

.infoContainer {
    width: 90%;
    color: azure;
    border-radius: 20px;
    background-image: url("../../assets/contact-us-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.infoCover {
    padding: 40px 24px;
    background-color: rgb(0, 0, 0, 0.35);
    border-radius: 20px;
}

.socialMediaContainer {
    margin-top: 32px;
}

.socialMediaContainer a{
    color: azure;
}

.formText p {
    font-size: 10px;
}

/* mobile screen  */
@media (max-width: 600px) {
    .infoContainer {
        width: 100%;
    }
}