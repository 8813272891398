.searchContainer {
    width: 230px;
}

/* tablet screen  */
@media (max-width: 768px) {
    .searchContainer{
      width: 220px;
    }
}

/* mobile screen  */
@media (max-width: 600px) {
    .searchContainer{
      width: 80%;
    }
}