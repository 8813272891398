.nav-row{
    --bs-gutter-x: 0
}

.sticky{
    position: sticky;
    top: 0;
    z-index: 999;
    width: 20%;
    min-height: 100vh;
}

.navbar-container{
    background-color: rgb(33,37,41) !important;
}

.link {
    color: rgba(255, 255, 255, 0.7);
    text-align: left;
    padding-left: 10px;
}

.link:hover, .link:focus {
    color: rgba(255, 255, 255, 0.7);
}

.link.active {
    background-color: white !important;
    color: rgb(33,37,41) !important;
}

.nav-item{
    cursor: pointer;
}

.offcanvas-end{
    width: 250px;
}

.offcanvas-header .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.menu-icon, .menu-icon:hover, .menu-icon:focus{
    background-color: transparent;
    border-color: white;
    box-shadow: none;
}

/* tablet screen  */
@media (max-width: 768px) {
    .sticky{
        width: 100%;
        min-height: auto;
    }
}

/* mobile screen  */
@media (max-width: 600px) {
    .sticky{
        min-height: auto;
        width: 100%;
    }
}