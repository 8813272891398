.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

.logo-container {
  height: 50px;
  width: 50px;
  position: relative;
  top: -61%;
  left: 20%;
}

.loader svg polygon {
  stroke-dasharray: 50;
  animation: dashoffset 1.5s linear infinite;
}

@keyframes dashoffset {
  to {
    stroke-dashoffset: 100;
  }
}

/* sync loader styles */
.spinner {
  margin: 28px auto;
  width: 60px;
  height: 12px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #333;
  height: 100%;
  width: 12px;
  display: inline-block;
  margin: auto 2px;
  border-radius: 50%;

  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}

.spinner div:nth-child(2) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner div:nth-child(3) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@keyframes stretchdelay {
  0%,
  40%,
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
  20% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
  }
}
