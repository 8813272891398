.not-found-container {
    background-color: #2E8BC0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 10rem;
    color: white;
    opacity: 0.7;
}