:root {
  --halfWhite: #edeef0;
  --orange: #e05b0d;
  --purple: #766599;
  --lightPurple: #8a73b9;
  --green: #488971;
  --lightGreen: #4bb891;
  --tangerine: #c08855;
  --lightTangerine: #ea9c52;
  --containerHeightLg: 530px;
  --containerHeightMd: 445px;
  --fontSm: 13px;
  --buttonBoxshadowOrange: 0 0 0 0.25rem rgb(224, 91, 13, 50%);
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, Verdana, Geneva, Tahoma,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  /* scrollbar Internet Explorer 10+ */
  scrollbar-width: none;
  /* scrollbar Firefox */
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-link.active {
  color: rgba(0, 0, 0, 0.55) !important;
}

.search {
  width: 45%;
}

.search .text-box {
  font-size: var(--fontSm);
}

.search .text-box:focus {
  outline: none;
  box-shadow: none;
  border-color: #ffc107;
  border-width: 2px;
}

.ql-editor {
  height: 200px;
}

.ql-tooltip {
  z-index: 999;
  box-shadow: none;
}

.font-small {
  font-size: var(--fontSm);
}

/* tablet screen  */
@media (max-width: 768px) {
  .search {
    width: 60%;
  }
}

/* mobile screen  */
@media (max-width: 600px) {
  .search {
    width: 80%;
  }
}
