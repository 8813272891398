.table {
  width: 100%;
  border: 1px solid #e0e0e0;
}

th {
  border: 1px solid #e0e0e0;
}

.heading {
  text-align: center;
  padding: 10px 0;
  margin: 0;
}

.borderBottom {
  border-bottom: 1px solid #e0e0e0;
}

.searchBox {
  padding: 10px;
  height: 55px;

  input {
    height: 35px;
  }
}

.data {
  max-width: 400px;
  text-align: center;
  padding: 10px;
  border: 1px solid #e0e0e0;
}

.footer {
  margin: 50px 0;
  display: flex;
  justify-content: space-between;
}

.paginator {
  display: flex;
  align-items: center;
}

.paginator span {
  cursor: pointer;
}

.pages {
  display: flex;
  align-items: center;
}

.page {
  padding: 0px 12px;
  border-right: 1px solid #e0e0e0;
  height: fit-content;
}

.page.active {
  text-decoration: underline;
  text-underline-offset: 5px;
  font-weight: bold;
}

.page:last-child {
  border-right: none;
}

.disabled {
  cursor: auto !important;
  color: #acacac;
}

.paginationText {
  font-size: 12px;
  max-width: 135px;
  text-align: right;
  display: flex;
  align-items: center;
}
