.blog-content {
    width: 80%;
}

.blog-content-description {
    line-height: 1.8;
    margin-top: 80px;
}

.blog-content-description li {
    margin-bottom: 10px;
}

/* tablet screen  */
@media (max-width: 768px) {
    .blog-content {
        width: 85%;
    }
}